<template>
  <v-card>
    <v-card-title>
      {{ $t("userEvents.title") }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="userEvents"
      :sort-by="['start']"
      :loading="loading"
      mobile-breakpoint="0"
      sort-desc
    >
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field v-model="filter.name" type="text"></v-text-field>
          </td>
          <td>
            <v-select
              v-model="filter.state"
              :items="states"
              :item-text="item => $t(`events.state.${item.text}`)"
              clearable
            ></v-select>
          </td>
          <td>
            <v-text-field v-model="filter.place" type="text"></v-text-field>
          </td>
          <td>
            <v-autocomplete
              v-model="filter.country"
              :items="getItemsByLang"
              auto-select-first
              clearable
            ></v-autocomplete>
          </td>
          <td>
            <v-text-field v-model="filter.startDate" type="text"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="filter.endDate" type="text"></v-text-field>
          </td>
        </tr>
      </template>
      <template v-slot:item.name="{ item }">
        <router-link :to="{ name: 'Overview', params: { id: item.id } }" exact>
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.state="{ item }">
        <EventStateChip :state="item.state" small></EventStateChip>
      </template>
      <template v-slot:item.country="{ item }">
        <CountryFlag
          v-if="item.country"
          :country="getItemById(item.country)"
        ></CountryFlag>
      </template>
      <template v-slot:item.start="{ item }">
        {{ $d(new Date(item.start * 1000), "shortDate") }}
      </template>
      <template v-slot:item.end="{ item }">
        <span v-if="item.start !== item.end">
          {{ $d(new Date(item.end * 1000), "shortDate") }}
        </span>
        <span v-else>
          -
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import EventStateChip from "@/components/EventStateChip";
import CountryFlag from "@/components/CountryFlag";

export default {
  name: "PanelUserEvents",
  components: {
    EventStateChip,
    CountryFlag
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      filter: {
        name: "",
        state: "",
        place: "",
        country: "",
        startDate: "",
        endDate: ""
      },
      states: [
        {
          text: "scheduled",
          value: "SCHEDULED"
        },
        {
          text: "active",
          value: "ACTIVE"
        },
        {
          text: "inactive",
          value: "INACTIVE"
        },
        {
          text: "live",
          value: "LIVE"
        },
        {
          text: "finished",
          value: "FINISHED"
        }
      ],
      loading: true
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      userEvents: state => state.user.userEvents
    }),
    ...mapGetters({
      getItemsByLang: "nations/getItemsByLang",
      getItemById: "nations/getItemById"
    }),
    headers() {
      return [
        {
          text: this.$i18n.t("events.table.name"),
          value: "name",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.filter.name).toLowerCase())
        },
        {
          text: this.$i18n.t("userEvents.table.state"),
          value: "state",
          filter: value => {
            if (this.filter.state) {
              return value === this.filter.state;
            }
            return true;
          }
        },
        {
          text: this.$i18n.t("userEvents.table.place"),
          value: "place",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.filter.place).toLowerCase())
        },
        {
          text: this.$i18n.t("userEvents.table.country"),
          value: "country",
          filter: value => {
            if (this.filter.country) {
              return value === this.filter.country;
            }
            return true;
          }
        },
        {
          text: this.$i18n.t("userEvents.table.startDate"),
          value: "start",
          filter: value =>
            this.$i18n
              .d(new Date(value * 1000), "shortDate")
              .toLowerCase()
              .includes(String(this.filter.startDate).toLowerCase())
        },
        {
          text: this.$i18n.t("userEvents.table.endDate"),
          value: "end",
          filter: value =>
            this.$i18n
              .d(new Date(value * 1000), "shortDate")
              .toLowerCase()
              .includes(String(this.filter.endDate).toLowerCase())
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      fetchUserEvents: "user/fetchUserEvents"
    }),
    ...mapMutations({
      setUserEvents: "user/setUserEvents"
    }),
    fetchItems() {
      this.loading = true;

      this.fetchUserEvents(this.user.id)
        .then(response => {
          this.setUserEvents(response.data.data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.fetchItems();
  }
};
</script>
